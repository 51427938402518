@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  flex-grow: 1;
}

.custom-height-default {
  height: 600px;
}

.custom-height-iframe {
  height: 1000px;
}
.custom-height-dashboard {
  height: 400px;
}

.custom-height-upcoming-appointment {
  height: 500px;
}

.custom-height-upcoming-appointment-adminstaff {
  height: 1000px;
}

.custom-height-appointment-history {
  height: 1000px;
}